import { expect } from 'chai'
import CryptoJS from 'crypto-js'

const Key = process.env.VUE_APP_CryptoTool_Key
/**
 * 加解密工具
 */
export class CryptoTool {
  /**
   * 加密数据
   * @param data
   */
  enCryptoData (data: string): string {
    expect(data, '参数data不允许为空').not.undefined.and.not.empty
    expect(Key, '没有配置VUE_APP_CryptoTool_Key环境变量').not.undefined.and.not.empty

    return CryptoJS.AES.encrypt(data, Key!).toString()
  }

  /**
   * 解密数据
   * @param data 加密后数据
   */
  deCryptoData (data: string): string {
    expect(data, '参数data不允许为空').not.undefined.and.not.empty
    expect(Key, '没有配置VUE_APP_CryptoTool_Key环境变量').not.undefined.and.not.empty
    const bytes = CryptoJS.AES.decrypt(data, Key!)
    return bytes.toString(CryptoJS.enc.Utf8)
  }
}

export default new CryptoTool()
