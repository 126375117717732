
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { AllFormState, FormContext, FormEvent, checkFormMatches } from '@/libs/form-state'
import { userHasRight } from '@/libs/user-rights'

@Component({
  name: 'stateButton'
})
export default class StateButton extends Vue {
    @Prop({
      type: String,
      required: false,
      default: 'default'
    })
    type!: string

    /**
     * 显示的表单状态列表
     */
    @Prop({
      type: Array,
      required: false,
      default: () => [AllFormState]
    })
    visiableStates!: Array<string>;

    /**
     * 可以编辑的状态列表
     */
    @Prop({
      type: Array,
      required: false,
      default: () => []
    })
    disableStates!: Array<string>;

    /**
     * 可查看的权限列表
     */
    @Prop({
      type: String,
      required: false
    })
    canVisiableRights?: string

    /**
     * 不禁用的权限列表
     */
    @Prop({
      type: String,
      required: false
    })
    notDisableRights?: string

    /**
     * 页面状态上下文
     */
    @Prop({
      type: Object,
      required: false,
      default: () => { return {} }
    })
    formPageContext!: FormContext<any, FormEvent>;

    /**
     * 当前是否显示
     */
    get visiable () {
      if (!this.formPageContext) {
        return userHasRight(this.canVisiableRights)
      } else {
        const visableR = userHasRight(this.canVisiableRights)
        const visableS = checkFormMatches(this.formPageContext, this.visiableStates)
        return visableR && visableS
      }
    }

    /**
     * 当前是否禁用
     */
    get disable () {
      if (!this.formPageContext) {
        return !userHasRight(this.notDisableRights)
      } else {
        const disableR = !userHasRight(this.notDisableRights)
        const disableS = checkFormMatches(this.formPageContext, this.disableStates)
        return disableR || disableS
      }
    }

    /**
     * 按钮点击事件
     */
    doClick () {
      this.$emit('click')
    }
}
