
import {
  Prop,
  Vue,
  Component

} from 'vue-property-decorator'
import {
  WithRightAndStateComponentMinx, WithModelComponentMinx
} from '@/libs/form-state'

/**
 * 带有状态的处理的FormItem
 */
@Component({
  name: 'stateFormItem',
  mixins: [WithRightAndStateComponentMinx, WithModelComponentMinx]
})
export default class StateFormItem extends Vue {
  model: any
  /**
   * 标题
   */
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  label!: string;

  /**
   * 相关的表单字段
   */
  @Prop({
    type: String,
    required: false
  })
  prop?: string;

  /// 指定lable的宽度
  @Prop({
    type: Number,
    required: false
  })
  labelWidth?: number

  /// 指定原生的 label 标签的 for 属性，配合控件的 element-id 属性，可以点击 label 时聚焦控件。
  @Prop({
    type: String,
    required: false
  })
  labelFor?: string
}
