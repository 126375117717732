
import {
  Prop,
  Vue,
  Component,
  Watch
} from 'vue-property-decorator'
import CollapsePanel from '@/components/collapse-panle.vue'
import { Interpreter } from 'xstate'
import StateButton from '@/components/state-formbutton.vue'
import {
  FormContext,
  FormEvent,
  WithRightAndStateComponentMinx,
  FormEventEdit,
  FormSchma,
  FormEventReload,
  FormEventSave,
  WithModelComponentMinx,
  FormEventInsert
} from '@/libs/form-state'
import { dispatchRemoveTag } from '@/store'
import 'vue-loading-overlay/dist/vue-loading.css'

/**
 * 基础数据视图页
 */
@Component({
  name: 'dataFormPage',
  components: { CollapsePanel, StateButton },
  mixins: [WithRightAndStateComponentMinx, WithModelComponentMinx]
})
export default class DataFormPage extends Vue {
  /**
   * 表单状态机服务
   */
  @Prop({
    type: Object,
    required: true
  })
  formStateSerive!: Interpreter<
    any,
    FormSchma,
    FormEvent,
    {
      value: any;
      context: any;
    }
  >;

  /**
   * 当前表单上下文
   */
  @Prop({
    type: Object,
    required: false,
    default: () => {
      return {}
    }
  })
  formContext?: FormContext<any, FormEvent>;

  /**
   * 当前表单的数据模型
   */
  @Prop({
    type: Object,
    required: false,
    default: () => {
      return {}
    }
  })
  model: any;

  /**
   * 校验方法
   */
  @Prop({
    type: Function,
    required: false
  })
  validate?: (callback: (valid?: boolean | undefined) => void) => void

  /**
   * 加载中标识列表
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  loading!: boolean;

  /**
   * 是否显示编辑按钮
   */
  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  showButtonEdit!: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  showButtonReset!: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  showButtonClose!: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  showButtonSave!: boolean

  @Watch('formContext')
  watchFormContext (formContext: any) {
    this.$set(this, 'formPageContext', formContext)
    this.$emit('formPageContextChange', formContext)
  }

  /**
   * 默认编辑操作
   * @param callback
   */
  onDefaultEdit () {
    this.formStateSerive?.send(FormEventEdit as any)
  }

  /**
   * 默认关闭操作
   */
  onDefaultClose () {
    this.$Modal.confirm({
      title: '确认',
      content: '<p>确认要关闭当前页面吗？</p>',
      onOk: () => {
        dispatchRemoveTag(undefined, this.$route)
        this.$router.back()
      }
    })
  }

  /// 默认重载
  onDefaultReload () {
    this.$Modal.confirm({
      title: '确认',
      content: '<p>确认要重置当前页面吗？当前修改的数据将丢失！</p>',
      onOk: () => {
        this.formStateSerive?.send(FormEventReload as any)
      }
    })
  }

  /// 默认新增
  onDefaultNew () {
    if (this.validate) {
      this.validate((valid) => {
        if (!valid) {
          this.$Message.error('数据检查失败，请修改数据后再保存')
          return
        }
        this.$Modal.confirm({
          title: '确认',
          content: '<p>确认要新增吗？</p>',
          onOk: () => {
        this.formStateSerive?.send(FormEventInsert as any)
          }
        })
      })
    } else {
      this.$Modal.confirm({
        title: '确认',
        content: '<p>确认要新增吗？</p>',
        onOk: () => {
        this.formStateSerive?.send(FormEventInsert as any)
        }
      })
    }
  }

  /// 默认保存
  onDefaultSave () {
    if (this.validate) {
      this.validate((valid) => {
        if (!valid) {
          this.$Message.error('数据检查失败，请修改数据后再保存')
          return
        }
        this.$Modal.confirm({
          title: '确认',
          content: '<p>确认要保存吗？</p>',
          onOk: () => {
        this.formStateSerive?.send(FormEventSave as any)
          }
        })
      })
    } else {
      this.$Modal.confirm({
        title: '确认',
        content: '<p>确认要保存吗？</p>',
        onOk: () => {
        this.formStateSerive?.send(FormEventSave as any)
        }
      })
    }
  }
}
